import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import Loader from '../Loader';
import MaskedInput from '../Forms/MaskedInput';
import { ResponseMessage } from '../ResponseMessage';
import getValidationErrors from '../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../Forms/SelectSimple';
import Whatsapp from '../WhatsAppCompartilhar';

import api from '../../services/api';
import { recaptchaKey } from '../../config';
import getCep from '../../services/cep';

interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
}

interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const states = [
    { uf: 'AC', label: 'Acre (AC)', value: 1 },
    { uf: 'AL', label: 'Alagoas (AL)', value: 2 },
    { uf: 'AP', label: 'Amapá (AP)', value: 3 },
    { uf: 'AM', label: 'Amazonas (AM)', value: 4 },
    { uf: 'BA', label: 'Bahia (BA)', value: 5 },
    { uf: 'CE', label: 'Ceará (CE)', value: 6 },
    { uf: 'DF', label: 'Distrito Federal (DF)', value: 7 },
    { uf: 'ES', label: 'Espírito Santo (ES)', value: 8 },
    { uf: 'GO', label: 'Goiás (GO)', value: 9 },
    { uf: 'MA', label: 'Maranhão (MA)', value: 10 },
    { uf: 'MT', label: 'Mato Grosso (MT)', value: 11 },
    { uf: 'MS', label: 'Mato Grosso do Sul (MS)', value: 12 },
    { uf: 'MG', label: 'Minas Gerais (MG)', value: 13 },
    { uf: 'PA', label: 'Pará (PA)', value: 14 },
    { uf: 'PB', label: 'Paraíba (PB)', value: 15 },
    { uf: 'PR', label: 'Paraná (PR)', value: 16 },
    { uf: 'PE', label: 'Pernambuco (PE)', value: 17 },
    { uf: 'PI', label: 'Piauí (PI)', value: 18 },
    { uf: 'RJ', label: 'Rio de Janeiro (RJ)', value: 19 },
    { uf: 'RN', label: 'Rio Grande do Norte (RN)', value: 20 },
    { uf: 'RS', label: 'Rio Grande do Sul (RS)', value: 21 },
    { uf: 'RO', label: 'Rondônia (RO)', value: 22 },
    { uf: 'RR', label: 'Roraima (RR)', value: 23 },
    { uf: 'SC', label: 'Santa Catarina (SC)', value: 24 },
    { uf: 'SP', label: 'São Paulo (SP)', value: 25 },
    { uf: 'SE', label: 'Sergipe (SE)', value: 26 },
    { uf: 'TO', label: 'Tocantins (TO)', value: 27 },
    { uf: '', label: 'Não sou Brasileiro', value: 28 },
  ];

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    try {
      formRef.current?.setErrors({});

      const shape = {
        name: Yup.string().required('O campo Nome é obrigatório'),

        email: Yup.string()
          .email('Preencha um email válido')
          .required('O campo Email é obrigatório'),
      };

      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/signup`, {
        ...data,

        captcha: captchaValue,
      });

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar seu cadastro',
      ]);
    }
    setLoading(false);
  };

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    const state = cepResponse.uf
      ? states.find(st => {
          return st.uf === cepResponse.uf;
        })
      : false;

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      if (state) {
        formRef?.current?.setFieldValue('state', state.value);
      }
    }
  };

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="success"
            title="Sucesso"
            description={
              <p>
                Cadastro realizado com sucesso!
                <br />
                <Link to="login">
                  <Button style={{ width: '100%' }} type="button">
                    Acessar
                  </Button>
                </Link>
                <br />
                Aproveite e indique para seus amigos!
                <Whatsapp
                  text="Olá! Acabei de me inscrever no Cobem 2020. Inscreva-se também!. https://cobem2020.encontrodigital.com.br"
                  number=""
                  message="Compartilhe!"
                />
              </p>
            }
          />
        ) : (
          <Form ref={formRef} onSubmit={handleNewLogin} initialData={{}}>
            <Input icon={FiUser} name="name" placeholder="Nome Completo" />
            <Input icon={FiMail} name="email" placeholder="E-mail" />

            {withPassword && (
              <Input
                icon={FiLock}
                name="password"
                type="password"
                placeholder="senha"
              />
            )}

            <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
            <Button style={{ width: '100%' }} type="submit">
              Cadastrar
            </Button>
          </Form>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
