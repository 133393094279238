import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';

import { Menu } from '../../../components/Menu';

import { useAuth } from '../../../hooks/Auth';
import FooterLayout from '../../Footer';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
const menuList = [
  {
    title: 'Home',
    target: '/',
    icon: '',
    external: 2,
    fluid: 1,
    targetID: '',
    status: 2,
  },
  {
    title: 'Poster',
    target: '/poster',
    icon: '',
    fluid: 1,
    external: 1,
    targetID: '',
    status: 2,
  },
  {
    title: 'Palestras',
    target: '/dashboard',
    targetID: '',
    fluid: 1,
    icon: '',
    status: 2,
  },
  {
    title: 'Gerenciador',
    target: '/manager',
    icon: '',
    fluid: 1,
    targetID: '',
    status: 2,
  },
];

const DefaultLayout: React.FC = ({ children }) => {


  return (
    <>
     <Wrapper> <Menu menuList={menuList} fixedMenu={false} />
      {children}
      <FooterLayout />
      <PopupComponent />
      <RoomChangeContainer />
      </Wrapper>
      {/*   <ImageSliderBackground slides={['/apoio/background/BG_amazonia_1_1.jpeg',
        '/apoio/background/BG_amazonia_2_1.jpeg',
        '/apoio/background/BG_amazonia_3_1.jpeg',
      '/apoio/background/BG_amazonia_4_1.jpeg',
  '/apoio/background/BG_amazonia_5_1.jpeg']} speed={15000}/>
      <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
            },
            move: {
              speed: 2,
              direction: 'top-right',

              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },
            },
            shape: {
              type: 'circle',
           
            },
            color: { value: 'rgb(0,200,100)' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 60,
            },
            size: {
              value: 12,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      />*/}
    </>
  );
};

export default DefaultLayout;
