import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { sanitizeData } from '../../core/components/FormComponent';
import { FormHandles } from '@unform/core';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}


const Sale: React.FC = () => {
  const endpoint = '/research';
  const title = 'Pesquisa de Satisfação';
  const formSchema = {
  };


  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID' },
       client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },
    p1: { ref: 'p1', column: 'p1', label: 'Pergunta 1' },
    p2: { ref: 'p2', column: 'p2', label: 'Pergunta 2' },
    p3: { ref: 'p3', column: 'p3', label: 'Pergunta 3' },
    p4: { ref: 'p4', column: 'p4', label: 'Pergunta 4' },
    p5: { ref: 'p5', column: 'p5', label: 'Pergunta 5' },
    p6: { ref: 'p6', column: 'p6', label: 'Pergunta 6' },
    p7: { ref: 'p7', column: 'p7', label: 'Pergunta 7' },
    p8: { ref: 'p8', column: 'p8', label: 'Pergunta 8' },
    p9: { ref: 'p9', column: 'p9', label: 'Pergunta 9' },
    p10: { ref: 'p10', column: 'p10', label: 'Pergunta 10' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  
  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
   
    ],
    bodyModules: [
     
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Sale;
