import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import { useAuth } from '../../hooks/Auth';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import Loader from '../../components/Loader';
import { Button } from '../../components/Button/styles';
import { useModal } from '../../hooks/Modal';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const MeusIngressos: React.FC = () => {

const {user} = useAuth();
const {addToast} = useToast();
const [tickets,setTickets] = useState<Array<Record<string,any>>>([]);
const [loading, setLoading] = useState(true);
const {addModal, removeModal} = useModal();

const removeTicket = async(id) => {
  removeModal('ingresso');
  setLoading(true)
  const response = await api.post(`/delete-sale/${id}`);

  if(response){
    addToast({ type: 'success', title: 'Removido com sucesso'});
    load();

    
  }
  else{
    setLoading(false)
    addToast({ type: 'error', title: 'Erro ao remover'});
  }
}

  const load = async () => {
    setLoading(true)
    const response = await api.get('/my-sales');

    if(response){
      setTickets(response.data);
      setLoading(false)
    }
    else{
      setTickets([]);
      setLoading(false)
    }
    

  }

  useEffect(() => {

    if(user){

      load();

    }

  },[])

  const handleDelete = (id: string): void => {
    addModal({
      title: 'Confirmar remoção?',
      key : 'ingresso',
      content: (
        <button style={{width: '200px'}} className="defaultButton" type="button" onClick={() => removeTicket(id)}>
          Sim
        </button>
      ),
      theme : "whiteModal"
    });
  };


  return (
    <> {loading && <Loader message="Carregando"/>}
<h1 style={{width:'100%', margin: '40px 0px', textAlign:'center'}}>Meus ingressos</h1>
      <Container >
      

        {user ? <table style={{width: '100%', maxWidth: '600px'}}>{tickets.length >= 1 ? tickets.map( t => <tr style={{ background:'rgba(0,0,0,0.3)'}}><td onClick={() => handleDelete(t.id)} title="Cancelar meu ingresso!" style={{width: '100px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', minWidth: '100px', padding: '10px'}}>Cancelar</td><td style={{padding: '0px 15px'}}>{t?.product_id_response?.title}</td></tr>) : <tr style={{ background:'rgba(0,0,0,0.3)'}}><td style={{padding: '10px'}}>Nenhum ingresso encontrado</td></tr>}</table> : <p>Você precisa estar logado para visualizar</p>}

      </Container>
    </>
  );
};
export default MeusIngressos;
