import styled, { keyframes } from 'styled-components';
import { defaultTheme } from '../../config';

interface IBackground {
  background?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: auto;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  transition: 0.5s all;
  width: 100%;

  > div {
    overflow-y: auto;
    padding: 40px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    color: #333;
    background: #fff;
    margin: 0;

    @media (max-width: 750px) {
      max-width: 100%;
      width: 100%;
    }

    > h2 {
      color: ${defaultTheme.defaultBackground};
      margin-bottom: 10px;
      text-align: center;
      justify-content: center;
      display: flex;
    }

    > p {
      color: #333;
      font-size: 12px;
    }

    > button {
      padding: 10px;
      margin: 5px auto;
      width: 200px;
      border-radius: 8px;
      background: ${defaultTheme.defaultBackground};
      color: #fff;
      border: 0px;
    }

    > div {
      color: #333;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;

      > h2 {
        color: ${defaultTheme.defaultBackground};
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
      }

      > p {
        color: #333;
        font-size: 12px;
      }

      > button {
        padding: 10px;
        margin: 5px auto;
        width: 200px;
        border-radius: 8px;
        background: ${defaultTheme.defaultBackground};
        color: #fff;
        border: 0px;
      }
    }
  }
`;
