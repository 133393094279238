export const hash = 'a24e400c-211b-11ec-8229-16614a6960bb';

// export const gateKey = '';
export const gateKey = '';
export const recaptchaKey = '6LdSuq8cAAAAANQFtS8cljpHmZ3itOjOpP7Ep_Rz';
export const apiUrl = `https://api-global.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://global-socket.encontrodigital.com.br/';
//export const apiSocket = 'http://localhost:8001';
//export const apiUrl = `http://localhost:8000/${hash}`;
export const urlWebsite = 'https://conhecer2021.encontrodigital.com.br';
export const supportNumber = `5551981476007`;
export const home = 'Home';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'Sebrae - Conhecer2021';
export const logo = '/assets/evento/logo.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'Sebrae - Conhecer2021';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
};

export const defaultTheme = {
  defaultBackground: 'rgb(58,128,79)',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: 'none',
    backgroundMobile: 'rgb(58,128,79)',
    color: '#fff',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
