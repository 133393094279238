import React, { useCallback, useRef, useEffect, useState } from 'react';

import ProgramComponent from '../../components/Program';
import { Container } from './styles';

const Program: React.FC = () => {
  return (
    <Container>
      <ProgramComponent />
    </Container>
  );
};
export default Program;
