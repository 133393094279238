import React from 'react';
import { Wrapper } from './styles';
import { Menu } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';

const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu } = usePages();

  const { token, user } = useAuth();




  return (
    <>
      <Wrapper>
        <Menu menuList={menu} fixedMenu={false} />
        {children}
   
        <PopupComponent />
        <RoomChangeContainer />
        
      </Wrapper>
      <FooterLayout />
    </>
  );
};

export default DefaultLayout;
