/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { ProductsContent } from './styles';
import { BuyContext } from '../../index';
import api from '../../../../services/api';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import { ContainerWhite } from '../../../Sobre/styles';
import { useLanguage } from '../../../../hooks/Language';
import { FaTicketAlt } from 'react-icons/fa';
import Whatsapp from '../../../../components/Whatsapp';
import { useAuth } from '../../../../hooks/Auth';

interface ProductProps {
  id: string;
  title: string;
  price: number;
  formattedPrice?: string;
  max_selectable_courses: number;
  type: 'free' | 'paid';
  benefits: string[];
  quantity: number;
  category_id?: number;
  available_quantities: number;
  limited_quantities: number;
  hash_link : string;
}

interface CoursesProps {
  id: string;
  title: string;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
  productId?: string;
}

interface IProductId {
  productId?: string;
}

const ProductsContainer: React.FC<ComponentProps> = ({
  step,
  setStep,
  buyContext,
  setBuyContext,
  productId = '0'
}) => {
 
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
const {translate} = useLanguage();
const [test, setTest] = useState<boolean>(true);

const { user} = useAuth();
  const [productList, setProductList] = useState<ProductProps[]>([]);



  useEffect(() => {
    const loadInfo = async (): Promise<void> => {
      const responseProducts =
        productId !== '0'
          ? await api.get(`/product/${productId}`)
          : await api.get('/products-list');

      if (!responseProducts) {
        addToast({
          title: 'Ops, Falha de conexão.',
          description: `Por favor, recarregue a tela e tente novamente`,
          type: 'error',
        });
      }

      const formatProducts: Array<ProductProps> = responseProducts.data.rows.map(
        (productValue: ProductProps) => ({
          ...productValue,
          formattedPrice: format(productValue?.price || ''),
          formattedPriceInstallment: format((productValue.price/5)),
        }),
      );

   

      const item = responseProducts?.data?.[0];

      

      setProductList(formatProducts);

      setLoading(false);
     
    };
 
    loadInfo();
 
    
  }, [addToast]);

  const handleProduct = useCallback(
    productInfo => {
      productInfo.quantity = 1;

      const changeBuyContext: BuyContext = {
        ...buyContext,
        products: [productInfo],
      };

      const maxCourses = productInfo.max_selectable_courses;
      const amount = changeBuyContext.products
        ? changeBuyContext.products.reduce(
            (prev, productMap) => prev + productMap.price * productMap.quantity,
            0,
          )
        : 0;

  

      setBuyContext({
        ...changeBuyContext,
        amount,
        maxCourses,
        productType: productInfo.type,
      });
      setStep([1, maxCourses > 0 ? 2 : 3]);
    },
    [buyContext, setBuyContext, setStep],
  );
  

  return (
    <>
      {loading ? (
        <Loader message="Carregando" />
      ) : (
        <AnimatedDiv visible={step[1] === 1}>
          <ProductsContent>
           <div style={{margin: '20px auto', flexDirection: 'column', maxWidth: '450px', padding: '20px', border: '2px solid #fff', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <h2>INFORMAÇÕES:</h2> <br/>
          <p>É permitida a inscrição em apenas uma das modalidades do seminário (presencial ou on-line), e em uma das Oficinas.</p><br/>
          <p>O INGRESSO SEMINÁRIO PRESENCIAL também fornece acesso a modalidade on-line.</p>
          </div>
    
            <p>{translate('Clique para selecionar o seu ingresso.')}</p>
            
          <h2 style={{margin: '10px auto', marginTop: '20px'}}>Acesso on-line</h2>

          <aside style={{maxWidth: '500px', width: '100%'}}>
          <table style={{width: '100%'}}>
            <tbody>
              {productList.map(productInfo => (  parseInt(productInfo?.id) === 8 ?
                <tr onClick={() => productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? false : handleProduct(productInfo)} className="flowUp" style={{display: 'flex', background: 'none', border : '1px solid #fff', padding: '10px', borderRadius: '10px', cursor: 'pointer', marginTop:'5px', justifyContent: 'space-between'}}>
                  <td style={{display:'flex', alignItems:'center', paddingRight: '20px', width:'100%'}}>
                    <div style={{width:'100%', display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
                  <h2 className="productTitle">{translate(productInfo.title)}</h2>
               
                  <div className="priceDiv" >
                    {productInfo.category_id &&
                      (productInfo.category_id === 1 ? (
                        <label className="eventoFisico" style={{color: 'yellow'}} >{translate('Ingresso presencial')}</label>
                      ) : (
                        <label className="eventoDigital"style={{color: 'orange'}}>{translate('Ingresso on-line')}</label>
                      ))}
                  
                  </div>
                  </div>
                  {productInfo.benefits && productInfo.benefits.length > 50 && <ul>
                 <li>
                      <strong>{translate('Benefícios')}</strong>
                    </li>
                    {productInfo.benefits.map(benefit => (
                      <li>
                        <p>
                          <FiChevronRight />
                        </p>
                        <p>{translate(benefit)}</p>
                      </li>
                    ))}
                  </ul>}
                  </td>
<td>
                  {productInfo &&
                  productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? (
                    <button
                      className="defaultButton"
                      type="button"
                    >
                      {translate('Vagas esgotadas')}
                    </button>
                  ) : (
                    <button
                      className="defaultButton" style={{padding: '5px', width: '12px', minWidth:'120px'}}
                      
                      type="button"
                    >
                      {productInfo.type === 'free' ? translate('Cadastrar') : 
                       <>
                      R$ {productInfo.formattedPrice} <FaTicketAlt size={20}/> </>
                   }
                    </button>
                  )}</td>
                </tr> : <></>
              ))}
</tbody>
</table>
            </aside>

          <h2 style={{margin: '10px auto', marginTop: '20px'}}>Acesso presencial</h2>
         <aside style={{maxWidth: '500px', width: '100%'}}>
          <table style={{width: '100%'}}>
            <tbody>
              {productList.map(productInfo => (  parseInt(productInfo?.id) === 9 ?
                <tr onClick={() => productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? false : handleProduct(productInfo)} className="flowUp" style={{display: 'flex', background: 'none', border : '1px solid #fff', padding: '10px', borderRadius: '10px', cursor: 'pointer', marginTop:'5px', justifyContent: 'space-between'}}>
                  <td style={{display:'flex', alignItems:'center', paddingRight: '20px', width:'100%'}}>
                    <div style={{width:'100%', display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
                  <h2 className="productTitle">{translate(productInfo.title)}</h2>
               
                  <div className="priceDiv" >
                    {productInfo.category_id &&
                      (productInfo.category_id === 1 ? (
                        <label className="eventoFisico" style={{color: 'yellow'}} >{translate('Obrigatório apresentar passaporte vacinal')}</label>
                      ) : (
                        <></>
                      ))}
                  
                  </div>
                  </div>
                  {productInfo.benefits && productInfo.benefits.length > 50 && <ul>
                 <li>
                      <strong>{translate('Benefícios')}</strong>
                    </li>
                    {productInfo.benefits.map(benefit => (
                      <li>
                        <p>
                          <FiChevronRight />
                        </p>
                        <p>{translate(benefit)}</p>
                      </li>
                    ))}
                  </ul>}
                  </td>
<td>
                  {productInfo &&
                  productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? (
                    <button
                      className="defaultButton"
                      type="button"
                    >
                      {translate('Vagas esgotadas')}
                    </button>
                  ) : (
                    <button
                      className="defaultButton" style={{padding: '5px', width: '12px', minWidth:'120px'}}
                      
                      type="button"
                    >
                      {productInfo.type === 'free' ? translate('Cadastrar') : 
                       <>
                      R$ {productInfo.formattedPrice} <FaTicketAlt size={20}/> </>
                   }
                    </button>
                  )}</td>
                </tr> : <></>
              ))}
</tbody>
</table>
            </aside>
          <h2 style={{margin: '10px auto', marginTop: '20px'}}>Oficinas presenciais</h2>

          <aside style={{maxWidth: '500px', width: '100%'}}>
          <table style={{width: '100%'}}>
            <tbody>
              {productList.map(productInfo => (  ( parseInt(productInfo?.id) === 11 || parseInt(productInfo?.id) === 12 || parseInt(productInfo?.id) === 13 || parseInt(productInfo?.id) === 14) ?
                <tr onClick={() => productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? false : handleProduct(productInfo)} className="flowUp" style={{display: 'flex', background: 'none', border : '1px solid #fff', padding: '10px', borderRadius: '10px', cursor: 'pointer', marginTop:'5px', justifyContent: 'space-between'}}>
                  <td style={{display:'flex', alignItems:'center', paddingRight: '20px', width:'100%'}}>
                    <div style={{width:'100%', display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
                  <h2 className="productTitle">{translate(productInfo.title)}</h2>
               
                  <div className="priceDiv" >
                    {productInfo.category_id &&
                      (productInfo.category_id === 1 ? (
                        <label className="eventoFisico" style={{color: 'yellow'}} >{translate('Obrigatório apresentar passaporte vacinal')}</label>
                      ) : (
                        <></>
                      ))}
                  
                  </div>
                  </div>
                  {productInfo.benefits && productInfo.benefits.length > 50 && <ul>
                 <li>
                      <strong>{translate('Benefícios')}</strong>
                    </li>
                    {productInfo.benefits.map(benefit => (
                      <li>
                        <p>
                          <FiChevronRight />
                        </p>
                        <p>{translate(benefit)}</p>
                      </li>
                    ))}
                  </ul>}
                  </td>
<td>
                  {productInfo &&
                  productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? (
                    <button
                      className="defaultButton"
                      type="button"
                    >
                      {translate('Vagas esgotadas')}
                    </button>
                  ) : (
                    <button
                      className="defaultButton" style={{padding: '5px', width: '12px', minWidth:'120px'}}
                      
                      type="button"
                    >
                      {productInfo.type === 'free' ? translate('Cadastrar') : 
                       <>
                      R$ {productInfo.formattedPrice} <FaTicketAlt size={20}/> </>
                   }
                    </button>
                  )}</td>
                </tr> : <></>
              ))}
</tbody>
</table>
            </aside>
     
          <h2 style={{margin: '10px auto', marginTop: '20px'}}>Oficinas on-line</h2>

          <aside style={{maxWidth: '500px', width: '100%'}}>
          <table style={{width: '100%'}}>
            <tbody>
              {productList.map(productInfo => (  parseInt(productInfo?.id) > 14 ?
                <tr onClick={() => productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? false : handleProduct(productInfo)} className="flowUp" style={{display: 'flex', background: 'none', border : '1px solid #fff', padding: '10px', borderRadius: '10px', cursor: 'pointer', marginTop:'5px', justifyContent: 'space-between'}}>
                  <td style={{display:'flex', alignItems:'center', paddingRight: '20px', width:'100%'}}>
                    <div style={{width:'100%', display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
                  <h2 className="productTitle">{translate(productInfo.title)}</h2>
               
                  <div className="priceDiv" >
                    {productInfo.category_id &&
                      (productInfo.category_id === 1 ? (
                        <label className="eventoFisico" style={{color: 'yellow'}} >{translate('Obrigatório apresentar passaporte vacinal')}</label>
                      ) : (
                        <></>
                      ))}
                  
                  </div>
                  </div>
                  {productInfo.benefits && productInfo.benefits.length > 50 && <ul>
                 <li>
                      <strong>{translate('Benefícios')}</strong>
                    </li>
                    {productInfo.benefits.map(benefit => (
                      <li>
                        <p>
                          <FiChevronRight />
                        </p>
                        <p>{translate(benefit)}</p>
                      </li>
                    ))}
                  </ul>}
                  </td>
<td>
                  {productInfo &&
                  productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? (
                    <button
                      className="defaultButton"
                      type="button"
                    >
                      {translate('Vagas esgotadas')}
                    </button>
                  ) : (
                    <button
                      className="defaultButton" style={{padding: '5px', width: '12px', minWidth:'120px'}}
                      
                      type="button"
                    >
                      {productInfo.type === 'free' ? translate('Cadastrar') : 
                       <>
                      R$ {productInfo.formattedPrice} <FaTicketAlt size={20}/> </>
                   }
                    </button>
                  )}</td>
                </tr> : <></>
              ))}
</tbody>
</table>
            </aside>
          
           
          </ProductsContent>
          
        </AnimatedDiv>
      )}
    </>
  );
};

export default ProductsContainer;
