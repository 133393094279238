import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';

const FooterLayout: React.FC = ({ children }) => {
  return (  
    <>
    <div style={{display: 'flex', background: "#fff", flexWrap: 'wrap', width: '100%', justifyContent: 'center', alignItems: 'flex-start'}}>
<img src="/apoio/rs.png" style={{width: '200px'}}/>
<img src="/apoio/smed.png" style={{width: '200px', margin: '10px'}}/>
<img src="/apoio/unisinos.png" style={{width: '100px', margin: '10px'}}/>
<img src="/apoio/getedu.png" style={{width: '200px', margin: '10px'}}/>


    </div>

    <div style={{display: 'flex', background: "#fff", width: '100%', justifyContent: 'center', alignItems: 'flex-start'}}>
<img src="/apoio/selo.png" style={{width: '150px'}}/>



    </div>
    
    <div style={{zIndex:-1}}>

    <img src="/apoio/elementos1.png" style={{top: '0px', left: '0px', position:'fixed', width:'25%'}}/>
    <img src="/apoio/elementos2.png" style={{bottom: '0px', right: '0px', position:'fixed', width:'25%'}}/>
  { /*
  <ImageSliderBackground slides={['/apoio/background/BG_amazonia_1_1.jpeg',
      '/apoio/background/BG_amazonia_2_1.jpeg',
      '/apoio/background/BG_amazonia_3_1.jpeg',
    '/apoio/background/BG_amazonia_4_1.jpeg',
  '/apoio/background/BG_amazonia_5_1.jpeg']} speed={15000}/>
  <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
            },
            move: {
              speed: 1.5,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 1,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'image',
              polygon: {
               
                nb_sides: 6,
                
              },
              image: {
                src: "/apoio/birds/bird2.gif",
                width: 100,
                height: 100
              }
            },
     
            color: { value: 'rgb(0,100,200)' },

            number: {
              density: {
                enable: true,
                value_area: 3400,
              },
              value: 25,
            },
            size: {
              value: 92,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 0
        }}
      />*/}
      </div> </>
  );
};

export default FooterLayout;
