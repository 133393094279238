import styled from 'styled-components';
import { defaultTheme } from '../../../../config';

export const ProductsContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;

  z-index: 1;

  > h1 {
    color: #fff;
  }

  .title {
  }

  .productTitle{
    font-size: 18px;
    width: 100%;

    @media (max-width: 750px) {
      font-size: 14px;
    }

  }

  .buyButton {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
    margin-left: 5px;

    margin: 0;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(60%);
    padding: 10px 20px !important;
    border-radius: 8px;

    width: 100% !important;
    max-width: 50%;
    border: 2px solid yellow;
  }

  .priceDiv {
    display: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;

    > .eventoDigital {
      color: #fff;
    }
    > .eventoFisico {
      color: #fff;
    }

    > h3 {
      font-size: 16px;
    }
  }

  > aside {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    align-items: stretch;
    width: 100%;

    @media (max-width: 750px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    > div {
      position: relative;
      padding: 30px 20px;
      width: 100%;
      max-width: 300px;
      min-width: 300px;
      margin: 15px;
      background: none;
      border: 2px solid yellow;
      color: #fff;
      box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
      border-radius: 8px;

      @media (max-width: 750px) {
        max-width: 300px;
        margin: 15px auto;
      }

      > h2 {
        font-size: 16px;
        margin-bottom: 0px;
        text-align: center;
        color: yellow !important;
        justify-content: center;
        min-height: 30px;

        @media (max-width: 750px) {
          justify-content: flex-start;
          text-align: left;
          min-height: auto;
          max-width: 100%;
        }

        display: flex;
        align-items: center;

        color: #023b72;
      }

      > h3 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 300;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        color: #fff;
      }

      > button {
        background: rgb(0, 100, 150);
        color: #fff;
        border: 0;
        transition: background 0.2s;
        padding: 15px;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        &:hover {
          background: rgb(0, 90, 130);
        }
      }

      > ul {
        margin-top: 10px;
        > li {
          list-style: none;
          display: flex;

          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
`;
