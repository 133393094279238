import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../core/components/FormComponent';
import { createSchemaByForm, IYup } from '../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import { useSearch } from '../../../../hooks/Search';
import Button from '../../core/components/Forms/Button';

import { usePrinter } from '../../../../hooks/PrinterHook';
import QRCodeComponent from '../../core/components/QRCodeComponent';
import { useAuth } from '../../../../hooks/Auth';

interface ICreateModule {
  lineData: Record<string, any>;
  title?: string;
}

export default function QRCodeChecker({
 
  lineData,
  title = '',

}: ICreateModule): JSX.Element {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();
  const key = uuid();
  const [haveQr,setHaveQr] = useState(1);
  const {user} = useAuth();

  const load = async() => {
    const haveqr = await api.post('/haveqr');

    if(haveqr && haveqr?.data?.status === 2){
      setHaveQr(2);
    }
    else{
      setHaveQr(1);
    }
  }

  useEffect(() => {
    load();

  },[user])

  useEffect(() => {
    if(user){
    load();
    }

  },[])

  const print = line => {
    printIt();
  };

  const handleQR = async (line): Promise<void> => {
   addPrint([
    <div className="qrHolder"
    style={{
      padding: '20px 5px',
      background: '#fff',
      width: '100%',
      textAlign: 'center',

    }}
  > <div style={{      display: 'flex',
  alignItems:'center',
  justifyContent:'center', width: '100%'}}> <img src="/assets/logo.png" style={{width: '250px'}}/>   <QRCode style={{ marginTop: '20px'}}

  size={300} 
          includeMargin={true}
          imageSettings={{src:"", width: 300, height: 300}}
  renderAs="svg"
  value={line.user_hash}
/></div>
   <div style={{marginTop: '20px', padding: '20px', textAlign: 'center', color: '#333'}}>
  {line.name && <h1>{line.name}</h1>}
<strong> Evento presencial:</strong><br/>
Imprima este documento com o QRCode ou salve no seu celular, pois será o seu ingresso no Evento.<br/><br/>

<strong> PRECAUÇÕES:</strong><br/>
Fique atentos às orientações abaixo para sua participação ser segura.
<br/><br/>
- O uso de máscara é obrigatório e a mesma deverá ser trocada a cada 2 horas de uso.<br/>
- Manter o distanciamento mínimo de 1,5m de outra pessoa.<br/>
- Higienizar as mãos frequentemente com água e sabão ou com álcool em gel a 70%.<br/>
<br/><br/>
- O ingresso ao evento somente após a aferição de temperatura.
<br/><br/>
- Apresentando qualquer sintoma da Covid-19, por favor, não se dirija ao local e procure orientação médica.
<br/><br/>
- Respeite os protocolos do evento.
<br/>
Contamos com sua colaboração.

</div>



    
  </div>]
    );
    const content =  <><h2>QRCode</h2><div className="qrHolder" style={{padding: '20px', background: '#fff'}}><QRCode
    renderAs="svg"
  
    size={300} 
    includeMargin={true}
    value={
      line.student && line.student.user_hash
        ? line.student.user_hash
        : line.user_hash
    }
  /></div> <Button onClick={() => print(line)}>Imprimir</Button></>;

    if (content) {
      const keyModal = addModal({ title, content, key });
    }
  };

  const moduleQRCode = (line): JSX.Element => (

 haveQr === 2 ? 
<div className="icon" style={{padding:'1px', background:'rgba(0,0,0,0.4)', borderRadius:'50%', border:'2px solid #fff'}}>
  <button title="Imprimir QRCode" className="lineIcon" type="button" onClick={() => handleQR(line)}>
<FaQrcode />
</button> </div>: <></>
  );

  return moduleQRCode(lineData);
}
