import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { authTitle } from '../config';

interface AuthState {
  token: string;
  user: Record<string, any>;
}

interface SignInCredentials {
  email: string;
  password?: string;
  name?: string;
}

interface ISignInAdmin {
  email: string;
  password?: string;
}

interface IAuthIntegration {
  token: string;
  user: Record<string, any>;
}

interface AuthContextData {
  user: Record<string, any>;
  token: string;
  signOut(): void;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInAdmin(credentials: ISignInAdmin): Promise<void>;
  AuthIntegration(credentials: IAuthIntegration): Promise<void>;
  updateImage(image : string) : void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(`@${authTitle}:token`);
    const user = localStorage.getItem(`@${authTitle}:user`);

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const updateImage = (image) => { 
    const {user,token} = data;
    user.image = image;
    localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));
      setData(state => ({ ...state, user }));

  }

  const signIn = useCallback(
    async ({ email, password = '', name = '' }: SignInCredentials) => {
      const response = await api.post('/sessions', { email, password, name });

      const { token, user } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem(`@${authTitle}:token`, token);
      localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));
      setData({ token, user });
    },
    [],
  );

  const AuthIntegration = useCallback(
    async ({ token, user }: IAuthIntegration) => {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem(`@${authTitle}:token`, token);
      localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));
      setData({ token, user });
    },
    [],
  );

  const signInAdmin = useCallback(
    async ({ email, password }: SignInCredentials) => {
      const response = await api.post('/sessionsAdmin', { email, password });

      const { token, user } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem(`@${authTitle}:token`, token);
      localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));
      setData({ token, user });
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem(`@${authTitle}:token`);
    localStorage.removeItem(`@${authTitle}:user`);

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        updateImage,
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        signInAdmin,
        AuthIntegration,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
