import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb, rgba } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import AreaExposicao from '../AreaExposicao';
import AreaInstitucional from '../AreaInstitucional';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo } from '../../config';
import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';


import Publicas from './modules/publicas';

import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import Program from '../../components/Program';
import Youtube from '../../components/Youtube';
import VideoGallery from '../../components/VideoGallery';
import Vimeo from '../../components/Vimeo';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}





const SignIn: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const [cardLayout, setCardLayout] = useState([] as Array<JSX.Element>);
  const { readPages } = usePages();

  const [pages, setPages] = useState(readPages());

  const [saibaMais,setSaibaMais] = useState(0);

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password });
        history.push('/dashboard');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signIn, addToast, history],
  );

  const buildCard: React.FC<IPublicData> = ({ icon, title, text }) => {
    return (
      <div
        onClick={() =>
          setCardLayout([<Publicas icon={icon} text={text} title={title} />])}
      >
        {icon({})}
        <p>{title}</p>
      </div>
    );
  };

  return (
    <>
      <Capa  style={{alignItems:'center'}}>
       <img src="/assets/logo3.png" style={{width: '100%', maxWidth:'600px', margin: '20px auto'}}/>
       
       <p>
<h1>Dias 28 e 29 de outubro de 2021, em Porto Alegre.</h1><br/>
</p>
<Link style={{margin:'10px auto'}} to="/login"><Button>ACESSAR</Button></Link>
       <Link style={{margin:'10px auto'}} to="/inscricao"><Button>ASSISTA A GRAVAÇÃO DO SEMINÁRIO</Button></Link>
       <Link to="/certificados"> <button className="defaultButtonReverse">BAIXE SEU CERTIFICADO</button> </Link>
       <p style={{maxWidth:'500px', margin: '10px auto', textAlign:'center'}}>
      <strong> De acordo com o Decreto estadual de 01/10/2021, para ingresso presencial ao evento é obrigatória a apresentação do comprovante de vacinação oficial (Conecte SUS ou Carteira de Vacinação) de acordo com o calendário de vacinação estadual.
<br/><br/>Acima de 40 anos: sistema vacinal completo
<br/>De 18 a 39 anos: 1ª dose</strong>
       </p>
       <img src="/apoio/sebrae.svg" style={{width: '100%', maxWidth:'100px', margin: '20px auto'}}/>
      </Capa>
      <Capa style={{ flexDirection:'row', padding: '50px 20px'}} className="alternativeBG">
        <div className="p50">
        <h2>ConheCER - EDUCAÇÃO EMPREENDEDORA QUE TRANSFORMA!</h2>
        <p>
<br/>O ConheCER é um Seminário Internacional itinerante de Educação Empreendedora que acontece desde 2017.
<br/><br/>	A Educação Empreendedora é um instrumento que potencializa uma educação inovadora, propositiva e relevante e que faz a diferença na atuação dos educadores, gestores públicos, gestores educacionais, transformando a vida dos estudantes e da sociedade.
<br/><br/>Enquanto a tecnologia transforma o mundo, a educação empreendedora tem o papel fundamental de preparar educadores e, consequentemente, os estudantes para esse cenário de transformação.
<br/><br/>	O CER tem como objetivo apoiar o educador e todo o ecossistema educacional a se preparar para uma educação que seja transformadora. 
<br/><br/>	A proposta é que juntos, o CER , o Estado anfitrião e o público possam discutir, refletir e propor mudanças, diante das diversas possibilidades apresentadas, por convidados nacionais e internacionais da área da educação e educação empreendedora.
<br/><br/>
<Link to="/inscricao"> <button className="defaultButton">VENHA PARTICIPAR!</button> </Link>

<br/><br/><strong>Na edição de 2021, em Porto Alegre, vamos abordar os seguintes temas:</strong>
<br/><br/> •  Tendências na educação
<br/><br/> • Movimento maker
<br/><br/> • Habilidades para o futuro
<br/><br/> • Experiências empreendedoras na educação
<br/><br/> • A importância da educação para a sociedade
<br/><br/> • Políticas Públicas
<br/>
<br/><br/><strong>E mais!</strong>

<br/><br/> • Seminário internacional presencial, com transmissão ao vivo.
<br/><br/> • Palestrantes internacionais.
<br/><br/> • Painéis.
<br/><br/> • Oficinas presenciais e on-line.
<br/><br/> • Experiências e espaços conceito.
<br/>
<br/><br/><strong> Dias 28 e 29 de outubro de 2021.</strong>
<br/><br/> 28/10 - das 8h30min às 18h30min.
<br/><br/> 29/ 10 - das 8h30min às 12h15min.
<br/>
<br/><br/>Local: FECOMÉRCIO - Rua Fecomércio S/N – Bairro Anchieta, Porto Alegre 
<br/><br/>Estacionamento gratuito no local
<br/><br/>Restaurante no local - pagamento apenas em cartão
</p>
        </div>
      </Capa>
      <Container id="video">
      <h1 style={{color:'#fff', padding: '20px'}}>ConheCER 2019 – Florianópolis</h1>
      <aside style={{width:'100%', maxWidth:'600px'}}><Vimeo id="617957682"/></aside>
      </Container>

      <Capa id="programacao" style={{ flexDirection:'row', padding: '50px 20px'}} className="alternativeBG">
        <div className="p50">
        <h1>PROGRAMAÇÃO</h1><br/><br/>

<h2 style={{marginTop:'40px'}}>Dia 28 de outubro, quinta-feira:</h2>
        <table className="table">
          <tr>
            <td>8h30</td>
            <td>Credenciamento</td>
          </tr>
          <tr>
            <td>9h</td>
            <td>Declamação Liliana Cardoso</td>
          </tr>
          <tr>
            <td>9h05</td>
            <td>Abertura com Guilhermina Abreu e Marcito Castro</td>
          </tr>

          <tr>
            <td>9h15</td>
            <td>Palestra Internacional – Professor Andrew Penaluña - ‘Lessons from Europe: Enterprising the educators’</td>
          </tr>
          <tr>
            <td>10h30</td>
            <td>Palestra Nacional - Daniela Degani – Competências Socioemocionais: atenção, equilíbrio emocional e compaixão!</td>
          </tr>
          <tr>
          <td>11h10</td>
            <td>Painel – Tendências na Educação – Metodologias Ativas para transformar a Educação! Com Fabiana Bigão, Fernando Trevisani, Lilian Bacich e Carolina Fonseca. Mediadora: Manu Bezerra.</td>
          </tr>
          <tr>
          <td>12h30</td>
            <td>Intervalo almoço </td>
          </tr>
          <tr>
          <td>14h00</td>
            <td>Show de humor com Diogo Almeida</td>
          </tr>
          <tr>
          <td>14h40</td>
            <td>Painel – Movimento Maker – fazendo do mundo um lugar melhor! Com Fábio Zsigmond, Tiago Sizanando. Mediação: Manu Bezerra </td>
          </tr>
          <tr>
          <td>16h10</td>
            <td>Intervalo</td>
          </tr>
          <tr>
          <td>16h30</td>
            <td>Painel – Experiências Educação Empreendedora e Políticas Públicas – Pedagogia Empreendedora - Com Fernando Dolabela, Cláudia Costin e Maria do Pilar Lacerda. Mediação: Manu Bezerra</td>
          </tr>
          <tr>
          <td>18h00</td>
            <td>Cerimônia de abertura </td>
          </tr>
          <tr>
          <td>18h30</td>
            <td>Encerramento  </td>
          </tr>

        </table>


        <h2 style={{marginTop:'40px'}}>Dia 29 de outubro, sexta-feira</h2>

        <table className="table">
          <tr>
            <td>8h30</td>
            <td>Recepção</td>
          </tr>
          <tr>
            <td>9h
              <br/>OFICINAS PRESENCIAIS 
            </td>
            <td>Oficina Ferramentas digitais – com Daniel Garrido e Cezar Lima da Get Edu <button onClick={() => setSaibaMais(saibaMais === 1 ? 0 : 1)} className="saibaMaisButton">{ saibaMais === 1 ? <FaChevronUp size={10}/> : <FaChevronDown size={10}/>} {saibaMais === 1 ? 'Recolher' : 'Saiba Mais'}</button><br/>
            
            { saibaMais === 1 &&<>
            <p className="fadeIn" style={{background: rgba(0,0,0,0.3), padding: '15px', fontSize: '14px'}}>Promover a percepção, a colaboração e compartilhamento de informações, além de vivenciar a utilização das ferramentas educacionais Google Workspace for Education em um ambiente seguro e gerenciado, auxiliando os professores a desenvolver novas habilidades com a utilização das ferramentas.</p><br/></>}
            <br/>
            
            
            BNCC, Educação Empreendedora e Projeto de Vida – com Priscila Boy<button onClick={() => setSaibaMais(saibaMais === 2 ? 0 : 2)} className="saibaMaisButton">{ saibaMais === 2 ? <FaChevronUp size={10}/> : <FaChevronDown size={10}/>} {saibaMais === 2 ? 'Recolher' : 'Saiba Mais'}</button><br/>
            
            { saibaMais === 2 &&<>
            <p className="fadeIn" style={{background: rgba(0,0,0,0.3), padding: '15px', fontSize: '14px'}}> Instrumentalizar o indivíduo, trazendo à luz os marcos legais, conceituais e pedagógicos e possibilitar a transposição didática para a prática pedagógica. </p><br/></>}
            
            
           
            
            
            <br/>
            Oficina tecnologias para Educação - com Gustavo Reis
            <button onClick={() => setSaibaMais(saibaMais === 3 ? 0 : 3)} className="saibaMaisButton">{ saibaMais === 3 ? <FaChevronUp size={10}/> : <FaChevronDown size={10}/>} {saibaMais === 3 ? 'Recolher' : 'Saiba Mais'}</button><br/>
            
            { saibaMais === 3 &&<>
            <p className="fadeIn" style={{background: rgba(0,0,0,0.3), padding: '15px', fontSize: '14px'}}> Explorar, na prática, três aspectos fundamentais relacionados à produção de conteúdo digital educacional. O primeiro aspecto é o do "hardware", que compreende a apresentação dos equipamentos básicos que compõem a infraestrutura de produção de conteúdo instrucional em vídeo e em áudio com boa qualidade, bem como a identificação dos requisitos mínimos de conectividade para a realização de atividades síncronas (ao vivo). O segundo aspecto é o do "software", cuja abordagem incluirá recomendações de aplicativos para uso tanto em dispositivos móveis quanto em computadores ao longo das etapas de produção, de edição e de transmissão. Finalmente, o terceiro aspecto é o da “criatividade”, em que serão identificadas ferramentas de apoio ao processo de ideação, à geração de pautas e à estimulação criativa, bem como funcionalidades disponíveis nas principais plataformas sociais que podem ser utilizadas para dar suporte a essas mesmas atividades.</p><br/></>}
            
            <br/>
            Oficina Changemakers -  com Professor Geraldo
            <button onClick={() => setSaibaMais(saibaMais === 4 ? 0 : 4)} className="saibaMaisButton">{ saibaMais === 4 ? <FaChevronUp size={10}/> : <FaChevronDown size={10}/>} {saibaMais === 4 ? 'Recolher' : 'Saiba Mais'}</button><br/>
            
            { saibaMais === 4 &&<>
            <p className="fadeIn" style={{background: rgba(0,0,0,0.3), padding: '15px', fontSize: '14px'}}> As mudanças em nossa vida nos últimos dois anos têm nos desafiado a ver o mundo de uma outra forma. Para aqueles que atuam com educação se tornar um Changemaker (transformador) se tornou uma necessidade, já que, as mudanças foram significativas. A oficina Changemakers tem como objetivo apresentar conhecimentos e ferramentas que possibilitem com que o professor possa ser um Changemaker e contribuir para a mudança dos seus alunos e do território onde vivem, abordando os novos conceitos de mundo, o que é ser um Changemaker, Educação Empreendedora e Inteligência Empreendedora.</p><br/></>}
            
            </td>
          </tr>
   
       
          <tr>
          <td >9h<br/>OFICINAS ON-LINE – com a Eu Ensino</td>
            <td>Aprendizagem baseada em problemas e projetos
            
              <button onClick={() => setSaibaMais(saibaMais === 5 ? 0 : 5)} className="saibaMaisButton">{ saibaMais === 5 ? <FaChevronUp size={10}/> : <FaChevronDown size={10}/>} {saibaMais === 5 ? 'Recolher' : 'Saiba Mais'}</button><br/>
            
            { saibaMais === 5 &&<>
            <p className="fadeIn" style={{background: rgba(0,0,0,0.3), padding: '15px', paddingLeft: '25px', fontSize: '14px'}}> 
            <ul>
          <li>Contexto metodologias ativas</li>
          <li>Movimento maker</li>
          <li>Prática estruturada</li>
          <li>Pontos de atenção</li>
            </ul>
            </p><br/></>}
            
              
              <br/>
              
              
              Ensino híbrido e sala de aula invertida
              
              <button onClick={() => setSaibaMais(saibaMais === 6 ? 0 : 6)} className="saibaMaisButton">{ saibaMais === 6 ? <FaChevronUp size={10}/> : <FaChevronDown size={10}/>} {saibaMais === 6 ? 'Recolher' : 'Saiba Mais'}</button><br/>
            
            { saibaMais === 6 &&<>
            <p className="fadeIn" style={{background: rgba(0,0,0,0.3), padding: '15px', paddingLeft: '25px', fontSize: '14px'}}>    
            <ul>
          <li>Definição de papéis</li>
          <li>Modelo de ensino híbrido</li>
          <li>Planejamento e execução</li>
          <li>4 pilares da aprendizagem invertida</li>
            </ul></p><br/></>}
              
              
              <br/>Gamificação, storytelling e simulações
              <button onClick={() => setSaibaMais(saibaMais === 7 ? 0 : 7)} className="saibaMaisButton">{ saibaMais === 7 ? <FaChevronUp size={10}/> : <FaChevronDown size={10}/>} {saibaMais === 7 ? 'Recolher' : 'Saiba Mais'}</button><br/>
            
            { saibaMais === 7 &&<>
            <p className="fadeIn" style={{background: rgba(0,0,0,0.3), padding: '15px', paddingLeft: '25px', fontSize: '14px'}}>    
            <ul>
          <li>Princípios de jogos</li>
          <li>Tecnologias e recursos</li>
          <li>Passo a passo da narrativa</li>
          <li>Simulações entre pares e equipes</li>
            </ul></p><br/></>}
            </td>
          </tr>    
          <tr>
            <td>11h30</td>
            <td>Palestra internacional - Saras Sarasvathy - “Entrepreneurship as a method for transforming lives and societies”</td>
          </tr>
          <tr>
            <td>12h30</td>
            <td>Encerramento</td>
          </tr>
        </table>
        <p>
 
</p>
        </div>
      </Capa>
    </>
  );
};
export default SignIn;
