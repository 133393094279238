import React, { Dispatch, useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowRight,
  FiArrowLeft,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FaArrowLeft } from 'react-icons/fa';
import { ISigla, readSigla } from '../../siglas';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../../components/Forms/Input';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { LoginContent } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import SelectSimple from '../../../../components/Forms/SelectSimple';

import Button from '../../../../components/Button';
import { BuyContext } from '../../index';
import api from '../../../../services/api';
import TextareaInput from '../../../../components/Forms/TextareaInput';
import Loader from '../../../../components/Loader';
import { countries } from './countries';
import getCep from '../../../../services/cep';
import { useLanguage } from '../../../../hooks/Language';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { authTitle, recaptchaKey } from '../../../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import states from './states';
import Axios from 'axios';
interface IRead {
  [key: string]: any;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
  productId : string;
}

interface SignInData {
  email: string;
  password: string;
}

interface LoginProps {
  [key: string]: string;
}

interface IOption{
  value: string;
  label: string;
}

interface ICaptcha {
  getValue(): string;
}

const LoginContainer: React.FC<ComponentProps> = ({
  buyContext,
  setBuyContext,
  setStep,
  step,
  productId
}) => {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { productType } = buyContext;
  const [buyType, setBuyType] = useState('');
  const [countryISO, setCountryISO] = useState('BR');
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);
  const {translate} = useLanguage();
  const [havePassword, setHavePassword] = useState(true);
  const siglas = readSigla();

  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])

  const handleLogin = useCallback(
    async (data: SignInData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),
        
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password });
        

        if(productType === 'free'){
          const response = await api.post('/free-sale', {product: buyContext?.products?.[0]?.id});
          if(response?.data?.confirmationCode){
            window.location.href = `/payment/success/${response?.data?.confirmationCode}`;
            setLoading(false);
          }
          else{  setLoading(false);
            addToast({type:'error', title:'Erro ao adicionar ingresso'});
            return;
          }
        }
        else{        setLoading(false);
          window.location.href = `/checkout/${buyContext?.products?.[0]?.hash_link}`;
        }
        setLoading(false);
        
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setLoading(false);
        }
        setLoading(false);
        addToast({
          type: 'error',
          title: translate('Erro'),
          description: translate(err.response.data.message || err.response.data.error || 'Erro ao finalizar operação'),
        });
      }
    },
    [signIn, addToast, buyContext, setBuyContext, setStep],
  );


  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const handleNewLogin = useCallback(
    async (data: LoginProps) => {

      console.log(data);
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

        const schema =   countryISO === 'BR' ? Yup.object().shape( {
          term: Yup.array().required(`${translate('Termo')} ${translate('obrigatório')}`),
          instituicao: Yup.string().required(`${translate('Categoria (Você é)')} ${translate('obrigatório')}`),
          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),

          phone: Yup.string().required(`${translate('Whatsapp ou celular')} ${translate('obrigatório')}`),
        

          document_number: Yup.string()
  
            .required(`${translate('CPF')} ${translate('obrigatório')}`),

          birth_date: Yup.string()
            .required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),

 
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string().required(`${translate('Estado')} ${translate('obrigatório')}`),

        } ) : Yup.object().shape( {
          term: Yup.array().required(`${translate('Termo')} ${translate('obrigatório')}`),
          instituicao: Yup.string().required(`${translate('Categoria (Você é)')} ${translate('obrigatório')}`),
          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),

          phone: Yup.string().required(`${translate('Whatsapp ou celular')} ${translate('obrigatório')}`),
        

          document_number: Yup.string()
  
            .required(`${translate('CPF')} ${translate('obrigatório')}`),

          birth_date: Yup.string()
            .required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),

 
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string()

            .required(`${translate('Estado')} ${translate('obrigatório')}`),
     
        });
        data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '');
        
        data.document_number = data?.document_number?.toString()?.replace(/[^0-9]/g, '');

       
        data.instituicao = data?.instituicao?.[0] || '';

    
        let {
          crm,
          name,
          repeat_email,
          email,
          password,
          company,
          phone,
          position,
          document_type,
          document_number,
          know_about,
          birth_date,
          company_document_number,
          extra_1,
          extra_2,
          extra_3,
          extra_4,
          education_id,
          especialidade,
          country,
          address,
          address_number,
          neighborhood,
          city,
          state,
          zipcode,
          gender,
          facebook,
          term,
          instituicao
        } = data;
        
        

    
        data.birth_date = data.birth_date.toString().replace(/[^0-9/]/g, '');
        await schema.validate(data, { abortEarly: false });

        

      

        const pais = countries.findIndex(c => c.value === country);
        if(pais){
          extra_3 = countries[pais].dialCode;
          extra_2 = countries[pais].label;
        }


        const dataRef : Record<string,any> = {
          crm,
          name,
          email,
          password,
          company,
          phone,
          know_about,
          position,
          document_type,
          document_number: document_number.toString().replace(/[^0-9]/g, ''),
          company_document_number,
          extra_1,
          extra_2,
          extra_3,
          extra_4,
          education_id,
          especialidade,
          country,
          birth_date : data.birth_date,
          address,
          address_number,
          neighborhood,
          city,
          state,
          zipcode,
          gender,
          term,
          captcha : captchaValue,
          instituicao
        };

        if(productType === 'free'){
          dataRef.product = buyContext?.products?.[0]?.id;
        }

        const newUser = await api
          .post('/signup', dataRef)
          .then( ( async(response) => {
     
            await signIn({ email, password });
    
        
            window.scrollTo(0,0);
            if(productType === 'free' && response){

              return window.location.href = `/payment/success/${response?.data?.confirmationCode}`;
    
            }
            else{
    
             return window.location.href = `/checkout/${buyContext?.products?.[0]?.hash_link}`;
            }

          }))
          .catch(error => {
            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              formRef.current?.setErrors(errors);
              setLoading(false);
            }

            addToast({
              type: 'error',
              title: error.response.data.message || error.response.data.error || 'Erro ao cadastrar',
            });
            setLoading(false);
            return false;
          });

        if (!newUser) {
          setLoading(false);
          return false;
        }
     

    

   
        setLoading(false);
        return false;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          const errorInfo = err.errors.reduce((error, prev) => {
            prev = `${prev}, ${error}`;
            return prev;
          });
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro de validação',
            description: errorInfo,
          });
        }
        console.log(err);
        setLoading(false);
      }
    },
    [addToast, buyContext, setBuyContext, setStep, signIn,countryISO],
  );

  return (
    <AnimatedDiv visible={step[1] === 3}>
      <LoginContent>
        {loading && <Loader message="Carregando" />}
        <div>
          {' '}
          <aside>
            {buyType === '' && (
              <AnimatedDiv  visible style={{paddingTop: '40px',background:'none'}}>
                <h2 className="defaultMarked" style={{marginBottom: '10px'}}><div/>{translate('Selecione a opção')}:</h2>
                <div style={{ display: 'flex', flexDirection:'column', alignItems:'center' }}>
                    <Button
                    
                    onClick={() => setBuyType('new')}
                  >
                    {translate('Novo Cadastro')}
                  </Button>
                  <Button onClick={() => setBuyType('login')}>
                    {translate('Já tenho cadastro')}
                  </Button>
                </div>
                <p 
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    fontSize:'12px',
                    color: '#fff',
                    marginTop: '20px'
                  }}
                  onClick={() => setStep([1,1])}
                 
                  
                >
                  <FaArrowLeft color="#fff" />
{' '}
{translate('Voltar')}
</p>
              </AnimatedDiv>
            )}
            {buyType !== '' && (
              <AnimatedDiv style={{background:'none'}} visible>
  

                {buyType === 'login' && (
                  <Form  className="form" ref={formRef} onSubmit={handleLogin}>
               
                    <h1 className="defaultMarked"><div/>{translate('LOGIN')}</h1>
                    <Input icon={FiMail} name="email" placeholder={translate('E-mail')} />
             
                    { havePassword && <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder={translate('Senha')}
                    />}
                    <Button type="submit">{translate('Entrar')}</Button>
                    { havePassword && <Link style={{ color: '#fff' }} to="/recuperar_senha">
                    {translate('Esqueci minha senha')}
                    </Link>}
                    <p 
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    fontSize:'12px',
                    color: '#fff',
                    marginTop: '20px'
                  }}
                  onClick={() => setBuyType('')}
                 
                  
                >
                  <FaArrowLeft color="#fff" />
{' '}
{translate('Voltar')}
</p>
                  </Form>
                  
                )}

                {buyType === 'new' && (
                  <Form className="form" ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR', extra_4: 2}}>
                    
                    <h1 className="defaultMarked" ><div/>{translate('Novo cadastro')}</h1>
<p style={{color: '#fff', fontSize: '12px', textAlign: 'center', margin: '10px 0px'}}>Estou ciente de que, ao preencher o formulário e finalizar minha inscrição, estarei fornecendo meus dados pessoais para acesso à plataforma, e ciente de que tais dados serão tratados e compartilhados apenas com os parceiros neste projeto.

</p>
                    <SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={translate('Pais')}/>
                    


                    <Input name="name" placeholder={translate('Nome completo')} />
                    <Input name="email" placeholder={translate('E-mail')} />
              

                    { countryISO === 'BR' ? <MaskedInput
                      mask="(99) 99999.9999"
                      name="phone"
                      placeholder={translate('Whatsapp ou Celular')}
                    /> : <Input name="phone"
                    placeholder={translate('Whatsapp ou Celular')}/>}

{ countryISO === 'BR' ? <MaskedInput
                      mask="999.999.999-99"
                      name="document_number"
                      placeholder={translate('CPF')}
                /> :  <Input name="document_number"
                placeholder={translate('Nº do documento')}/> }

                

                    <MaskedInput
                      mask="99/99/9999"
                      name="birth_date"
                      placeholder={translate('Data de Nascimento (DD/MM/YYYY)')}
                    />


<SelectSimple setCallback={ (value) => {
  setStateValue(value);
}} options={states} name="state" label="Estado"/>

<SelectSimple options={cities} name="city" label="Município"/> 

           { havePassword &&         <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder={translate('Cadastre sua senha de acesso')}
                    />}
<aside style={{display:'flex', justifyContent:'flex-start', width: '100%'}}>
<CheckboxInput
              style={{ display: 'flex'  }}
              name="instituicao"
              unique={true}
              preText={(
                <>
                  {' '}
                  Você é
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Professor`,
                  label: 'Professor',
                  value: `Professor`,
                },
                {
                  id: `Gestor Escolar`,
                  label: 'Gestor Escolar',
                  value: `Gestor Escolar`,
                },
                {
                  id: `Público Geral`,
                  label: 'Público Geral',
                  value: `Público Geral`,
                },
 
              ]}
            />
</aside>
<aside style={{display:'flex', justifyContent:'flex-start', width: '100%'}}>
<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  O {authTitle} deseja enviar comunicações, via e-mail e SMS, tais como conteúdos especiais, newsletter, promoções e divulgações do projeto. Assinale abaixo se você aceita recebê-las:.
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, aceito receber comunicações por e-mail e SMS`,
                  label: 'Sim, aceito receber comunicações por e-mail e SMS',
                  value: `Sim, aceito receber comunicações por e-mail e SMS`,
                },
                {
                  id: `Aceito apenas receber comunicações por e-mail`,
                  label: 'Aceito apenas receber comunicações por e-mail',
                  value: `Aceito apenas receber comunicações por e-mail`,
                },
                {
                  id: `Aceito apenas receber comunicações por SMS`,
                  label: 'Aceito apenas receber comunicações por SMS',
                  value: `Aceito apenas receber comunicações por SMS`,
                },
                {
                  id: `Não aceito receber comunicações`,
                  label: 'Não aceito receber comunicações',
                  value: `Não aceito receber comunicações`,
                },
              ]}
            />
</aside>
<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#fff',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li e aceito a')}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#fff' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('POLÍTICA DE PRIVACIDADE')}
{' '}
                  </Link>
{' '}
                </strong>
                {translate('do evento.')}
              </p>
              <aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
</aside>


                    <Button type="submit">
                      {productType === 'free' ? translate('Quero me inscrever!') : translate('Continuar')}

                    </Button>
                  </Form>
                )}
              </AnimatedDiv>
            )}
          </aside>
        </div>
      </LoginContent>
    </AnimatedDiv>
  );
};

export default LoginContainer;
