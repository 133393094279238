import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { recaptchaKey } from '../../../config';
import getCep from '../../../services/cep';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useModal } from '../../../hooks/Modal';
import { useToast } from '../../../hooks/Toast';

interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
}

interface ICaptcha {
  getValue(): string;
}

interface IProps {
  id : number;
}

const FormContainer : React.FC<IProps> = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const {removeModal} = useModal();
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {addToast} = useToast();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    try {
      formRef.current?.setErrors({});

      const shape = {
        p1: Yup.string().required('Por favor, responda a pergunta 1'),
        p2: Yup.string().required('Por favor, responda a pergunta 2'),
        p3: Yup.string().required('Por favor, responda a pergunta 3'),
        p4: Yup.string().required('Por favor, responda a pergunta 4'),
        p5: Yup.string().required('Por favor, responda a pergunta 5'),
        p6: Yup.string().required('Por favor, responda a pergunta 6'),
        p7: Yup.string().required('Por favor, responda a pergunta 7'),
        p8: Yup.string().required('Por favor, responda a pergunta 8'),
 

      };

      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/send-research`, {
        ...data,
        client_id : id
      });

      removeModal('research');
      addToast({ title: 'Pesquisa de satisfação enviada', description: 'Você já pode acessar seu certificado!', type: 'success'})
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao cadastrar sua pesquisa',
      ]);
    }
    setLoading(false);
  };



  return (
    <>
      {loading === true && (
        <Loader message="Carregando..." position="fixed" />
      )}

      <aside>
     
          <Form ref={formRef} onSubmit={handleNewLogin} initialData={{}}>
            <strong style={{margin: '10px 0px', maxWidth: '500px', color: "#333"}}>Antes de acessar seu certificado, pedimos que preencha nossa pesquisa de satisfação.</strong>
            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>1) Qual a probabilidade de você recomendar o ConheCER 2021 para um amigo ou colega?</p>
            <SelectSimple name="p1" label="" options={[
              { label: '0', value: '0'},
              { label: '1', value: '1'},
              { label: '2', value: '2'},
              { label: '3', value: '3'},
              { label: '4', value: '4'},
              { label: '5', value: '5'},
              { label: '6', value: '6'},
              { label: '7', value: '7'},
              { label: '8', value: '8'},
              { label: '9', value: '9'},
              { label: '10', value: '10'},
            ]}/>
 
            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>2) De modo geral, qual seu grau de satisfação em relação ao evento ConheCER 2021?</p>
            <SelectSimple name="p2" label="" options={[
              { label: 'Ruim', value: 'Ruim'},
              { label: 'Razoável', value: 'Razoável'},
              { label: 'Bom', value: 'Bom'},
              { label: 'Muito bom', value: 'Muito bom'},
              { label: 'Excelente', value: 'Excelente'},
            ]}/>

            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Por favor, avalie os seguintes pontos do evento:</p>

            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>3) Organização </p>
            <SelectSimple name="p3" label="" options={[
              { label: 'Ruim', value: 'Ruim'},
              { label: 'Razoável', value: 'Razoável'},
              { label: 'Bom', value: 'Bom'},
              { label: 'Muito bom', value: 'Muito bom'},
              { label: 'Excelente', value: 'Excelente'},
            ]}/>

            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>4) Equipe de suporte </p>
            <SelectSimple name="p4" label="" options={[
              { label: 'Ruim', value: 'Ruim'},
              { label: 'Razoável', value: 'Razoável'},
              { label: 'Bom', value: 'Bom'},
              { label: 'Muito bom', value: 'Muito bom'},
              { label: 'Excelente', value: 'Excelente'},
              { label: 'Assisti por transmissão on-line', value: 'Assisti por transmissão on-line'},
            ]}/>

            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>5) Local do Evento </p>
            <SelectSimple name="p5" label="" options={[
              { label: 'Ruim', value: 'Ruim'},
              { label: 'Razoável', value: 'Razoável'},
              { label: 'Bom', value: 'Bom'},
              { label: 'Muito bom', value: 'Muito bom'},
              { label: 'Excelente', value: 'Excelente'},
              { label: 'Assisti por transmissão on-line', value: 'Assisti por transmissão on-line'},
            ]}/>

            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>6) Programação do Seminário </p>
            <SelectSimple name="p6" label="" options={[
              { label: 'Ruim', value: 'Ruim'},
              { label: 'Razoável', value: 'Razoável'},
              { label: 'Bom', value: 'Bom'},
              { label: 'Muito bom', value: 'Muito bom'},
              { label: 'Excelente', value: 'Excelente'},
              { label: 'Não participei do seminário', value: 'Não participei do seminário'},
            ]}/>

            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>7) Qualidade das Oficinas </p>
            <SelectSimple name="p7" label="" options={[
              { label: 'Ruim', value: 'Ruim'},
              { label: 'Razoável', value: 'Razoável'},
              { label: 'Bom', value: 'Bom'},
              { label: 'Muito bom', value: 'Muito bom'},
              { label: 'Excelente', value: 'Excelente'},
              { label: 'Não participei das oficinas', value: 'Não participei das oficinas'},
            ]}/>

<p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>8) Plataforma de inscrição</p>
<SelectSimple name="p8" label="" options={[
              { label: 'Ruim', value: 'Ruim'},
              { label: 'Razoável', value: 'Razoável'},
              { label: 'Bom', value: 'Bom'},
              { label: 'Muito bom', value: 'Muito bom'},
              { label: 'Excelente', value: 'Excelente'},
              { label: 'Me inscrevi no local do evento', value: 'Me inscrevi no local do evento'},
            ]}/>

            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>9) Como ficou sabendo do evento?</p>
            <TextareaInput name="p9"/>

            <p style={{color: "#333", marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>10) Por favor, registre aqui suas sugestões e comentários:</p>
            <TextareaInput name="p10"/>
            
            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
            <aside style={{display: 'flex', justifyContent:'center', width: '100%'}}>
            <Button style={{ width: '100%', margin: '10px auto' }} type="submit">
              Enviar
            </Button>
            </aside>
          </Form>
        
      </aside>
    </>
  );
};

export default FormContainer;
