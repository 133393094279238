import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';

const bgAnima = keyframes`
0% {
  background: url('/apoio/background/BG_amazonia_1.jpeg') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position:  bottom;
}


20%{
  background: url('/apoio/background/BG_amazonia_2.jpeg') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position:  bottom;
}


45%{
  background: url('/apoio/background/BG_amazonia_3.jpeg') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position:  bottom;
}

70%{
  background: url('/apoio/background/BG_amazonia_4.jpeg') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position:  bottom;
}

95%{
  background: url('/apoio/background/BG_amazonia_5.jpeg') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position:  bottom;
}
100% {
  background: url('/apoio/background/BG_amazonia_1.jpeg') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position:  bottom;

}
`;




const waveTop = keyframes`
from {

  top: -210px;
  transform: rotateX(0deg);
}
to{

  top: -180px;
  transform: rotateX(30deg);
}
`;

const waveBottom = keyframes`
from {

  bottom: -210px;
  transform: rotateX(0deg);
}
to{

  bottom: -180px;
  transform: rotateX(30deg);

}
`;

export default createGlobalStyle`

@font-face {
    font-family: Montserrat;
    src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}


@font-face {
    font-family: MontserratBold;
    src: url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;


}

body {
/* background: url('/apoio/background/BG_amazonia_1.jpeg') no-repeat center;
  background-position:  bottom;
*/

  background: url('/assets/Fundo.jpg') ;
  background-position:  top;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;


/*
  transition: background 15s;
  animation: ${bgAnima} 50s infinite;
  */

  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

#root{
  min-height: 100vh;


}

.alternativeBG{
  background: url('/assets/evento/Fundo.jpg') no-repeat center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'MontserratBold',Helvetica,  sans-serif;

}

h2{

      font-size: 16px;
}

p, span{
  font-family:'Montserrat',Helvetica,  sans-serif;
}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}

.fixedTop {
 position: fixed;
 top: -210px;
 animation: ${waveTop} 5s infinite alternate-reverse ease;
 left: 0;
}

.fixedBottom{
  position: fixed;
 bottom: -210px;
 animation: ${waveBottom} 5s infinite alternate-reverse;
 left: 0;

}

.computerOnly{
display: flex;
@media (max-width: 1200px) {
    display: none;
  }
}

.mobileOnly{
display: none;
@media (max-width: 1200px) {
    display: flex;
  }
}

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: #e46218 !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 10px;
      text-align: center;
      background: #e46218 !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonMini, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: ${defaultTheme.defaultBackground} !important;
      color: ${defaultTheme.defaultColor}  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme.defaultBackground}  !important;
      }
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      background: #555;
      color: #fff;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #333  !important;
  border: 1px solid #ddd  !important;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2)  !important;

  span{
    color: #333  !important;
  }

  input {
    color: #333  !important;

    &::placeholder {
    color: #333  !important;
  }
  }
}
}
`;
