import React from 'react';

import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';

import { ModalProvider } from './Modal';
import { SearchProvider } from './Search';
import { ScrollProvider } from './Scroll';
import { SocketProvider } from './Socket';
import { PagesProvider } from './Pages';
import { InteractionProvider } from './Interactions';
import { LanguageProvider } from './Language';
import { PrinterProvider } from './PrinterHook';
const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <LanguageProvider>
      <PrinterProvider>
      <PagesProvider>
      <SocketProvider>
        <InteractionProvider>
        <ToastProvider>
          <ModalProvider>
            <SearchProvider>
              <ScrollProvider>{children}</ScrollProvider>
            </SearchProvider>
          </ModalProvider>
        </ToastProvider>
        </InteractionProvider>
      </SocketProvider>
      </PagesProvider>
      </PrinterProvider>
      </LanguageProvider>
    </AuthProvider>
  );
};

export default AppProvider;
