import React, { useState } from 'react';

import { FaPlus, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import {
  Container,
  Grid,
  Speaker,
  SpeakerLine,
  SpeakerListContainer,
  ContainerWhite,
} from './styles';
import { urlWebsite } from '../../config';

import { Modal } from '../ModalContainer/styles';
import  ContactIcons  from './modules/social';

interface ISpeakerData {
  name?: string;
  photo: string;
  position?: string;
  description?: string;
  id?: number;
  category_response ?: Record<any,string>;
  social ?: Record<any,string>;

}

interface IGrid {
  id?: string;
  speakers?: Array<ISpeakerData>;
}

interface IModal {
  speaker: ISpeakerData;
}

interface ISpeaker {
  background: string;
}

interface ISpeakers {
  type?: 'list' | 'grid';
  speakers?: Array<ISpeakerData>;
}



const SpeakerGrid: React.FC<IGrid> = ({
  speakers = [],
  id = 'palestrantes',
  children,
}) => {
  const [activeSpeaker, setActiveSpeaker] = useState(0);
  const [selectedSpeaker, setSelectedSpeaker] = useState<ISpeakerData>(
    {} as ISpeakerData,
  );

  const showSpeaker = speaker => {
    if (speaker.id === activeSpeaker) {
      setActiveSpeaker(-1);
      setSelectedSpeaker({} as ISpeakerData);
    } else {
      setActiveSpeaker(speaker.id);
      setSelectedSpeaker(speaker);
    }
  };

  return (
    <Container>
      <div className="title">{children}</div>

      {activeSpeaker < 1 && (
        <Grid>
          {speakers.map(speaker => {
            return (
              <Speaker
                onClick={() => showSpeaker(speaker)}
                background={
                  speaker.photo
                    ? `${urlWebsite}/${speaker.photo}`
                    : 'assets/evento/logo2.png'
                }
              >
        
           
              </Speaker>
            );
          })}
        </Grid>
      )}
      {activeSpeaker > 0 && (
        <>
          <button
            style={{ marginBottom: '10px' }}
            className="closeButton defaultButton"
            type="button"
            onClick={() => showSpeaker(selectedSpeaker)}
          >
            Fechar
          </button>
          <SpeakerModal speaker={selectedSpeaker} />
        </>
      )}
    </Container>
  );
};

export const SpeakerModal: React.FC<IModal> = ({ speaker }) => {
  return (
    <>
      <ContainerWhite style={{alignItems: 'flex-start'}}>

        <div >
          <h2> {speaker.name} </h2>
          <p>{speaker.category_response && speaker.category_response.title}</p>
          <strong> {speaker.position} </strong>
          <p
            style={{ marginTop: '15px' }}
            dangerouslySetInnerHTML={{
              __html: speaker.description || '',
            }}
          />
        </div>
      </ContainerWhite>
    </>
  );
};

export const SpeakerList: React.FC<IGrid> = ({
  speakers = [],
  id = 'palestrantes',
  children,
}) => {
  const [activeSpeaker, setActiveSpeaker] = useState(0);
  const [selectedSpeaker, setSelectedSpeaker] = useState<ISpeakerData>(
    {} as ISpeakerData,
  );

  const showSpeaker = speaker => {
    if (speaker.id === activeSpeaker) {
      setActiveSpeaker(0);
      setSelectedSpeaker({} as ISpeakerData);
    } else {
      setActiveSpeaker(speaker.id);
      setSelectedSpeaker(speaker);
    }
  };

  return (
    <Container>
      <div className="title">{children}</div>

      {activeSpeaker < 1 && (
        <SpeakerListContainer>
          {speakers.map(speaker => {
            return (
              <SpeakerLine
                onClick={() => showSpeaker(speaker)}
                background={
                  speaker.photo
                    ? `${urlWebsite}/${speaker.photo}`
                    : 'assets/evento/logo2.png'
                }
              >
                <div className="speakerPhoto" />

                <div className="speakerInfo">
                  
                  <h2>{speaker.name}</h2>
                  <p>{speaker.category_response && speaker.category_response.title}</p>
                  <p>{speaker.position}</p>
                </div>
              </SpeakerLine>
            );
          })}
        </SpeakerListContainer>
      )}
      {activeSpeaker > 0 && (
        <>
          <button
            style={{ marginBottom: '10px' }}
            className="closeButton defaultButton"
            type="button"
            onClick={() => showSpeaker(selectedSpeaker)}
          >
            Fechar
          </button>
          <SpeakerModal speaker={selectedSpeaker} />
        </>
      )}
    </Container>
  );
};

export const SpeakersContainer: React.FC<ISpeakers> = ({ speakers }) => {
  const [type, setType] = useState('grid');
  if (type === 'list') {
    return (
      <SpeakerList speakers={speakers}>
  
        <p>Clique na poesia para visualizar.</p>
      </SpeakerList>
    );
  }
  if (type === 'grid') {
    return (
      <SpeakerGrid speakers={speakers}>
   
        <p>Clique na poesia para visualizar.</p>
      </SpeakerGrid>
    );
  }
  return <></>;
};

export default SpeakerGrid;
