import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiPhone } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaAddressCard,
} from 'react-icons/fa';

import { MdError, MdDateRange } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey } from '../../../config';
import getCep from '../../../services/cep';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import { useLanguage } from '../../../hooks/Language';
import { countries } from '../../../pages/Products/modules/Login/countries';
import { useToast } from '../../../hooks/Toast';
import { AnimatedDiv } from '../../DashboardPages/core/components/Profile/styles';
import Axios from 'axios';
import { useAuth } from '../../../hooks/Auth';
import states from './states';

interface IRecoverFormData {
  name: string;
  email: string;
  repeat_email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
 country: string;
 extra_2 : string;
 extra_3 : string;
 hash : string;
 instituicao ?: any;
}

interface IOption{
  value: string;
  label: string;
}

interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {addToast} = useToast();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const [countryISO, setCountryISO] = useState('BR');
  const { translate} = useLanguage();
  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');
  
const {signIn} = useAuth();
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }
  

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

      data.instituicao = data?.instituicao[0] ?  data?.instituicao[0] : '';

    try {
      formRef.current?.setErrors({});

      const schema =    Yup.object().shape( {
       
        hash: Yup.string().required(`${translate('Código de cortesia')} ${translate('obrigatório')}`),

    
        term: Yup.array().required(`${translate('Termo')} ${translate('obrigatório')}`),
          instituicao: Yup.string().required(`${translate('Categoria (Você é)')} ${translate('obrigatório')}`),
          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),

          phone: Yup.string().required(`${translate('Whatsapp ou celular')} ${translate('obrigatório')}`),
        

          document_number: Yup.string().required(`${translate('CPF')} ${translate('obrigatório')}`),

          birth_date: Yup.string().required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),

 
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string().required(`${translate('Estado')} ${translate('obrigatório')}`),
   
      } );

      let {email, hash, country, extra_2, extra_3} = data;


      const pais = countries.findIndex(c => c.value === country);
      if(pais){
        extra_3 = countries[pais].dialCode;
        extra_2 = countries[pais].label;
      }

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/signup-cortesy`, {
        ...data,
        hash,
        captcha: captchaValue,
        extra_2,
        extra_3
      });


     
      await signIn({ email });
    
        
      window.scrollTo(0,0);
      if(response?.data?.confirmationCode){

        return window.location.href = `/payment/success/${response?.data?.confirmationCode}`;

      }

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        console.log(err);
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
    
        err?.response?.data?.error || err?.response?.data?.message || 'Erro ao gerar cadastro',
    ]);
    
    }
    setLoading(false);
  };

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    const state = cepResponse.uf
      ? states.find(st => {
          return st.uf === cepResponse.uf;
        })
      : false;

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
    
        formRef?.current?.setFieldValue('state', cepResponse.uf);
      
    }
  };

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

<aside>
         
      
  


        
                  <Form className="form" ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR', extra_4: 2}}>
                    
                    <h1 className="defaultMarked" ><div/>{translate('Novo cadastro')}</h1>
                    <Input name="hash" placeholder={translate('Código de cortesia')} />
                    <SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={translate('Pais')}/>
                    


                    <Input name="name" placeholder={translate('Nome completo')} />
                    <Input name="email" placeholder={translate('E-mail')} />
              

                    { countryISO === 'BR' ? <MaskedInput
                      mask="(99) 99999.9999"
                      name="phone"
                      placeholder={translate('Whatsapp ou Celular')}
                    /> : <Input name="phone"
                    placeholder={translate('Whatsapp ou Celular')}/>}

{ countryISO === 'BR' ? <MaskedInput
                      mask="999.999.999-99"
                      name="document_number"
                      placeholder={translate('CPF')}
                /> :  <Input name="document_number"
                placeholder={translate('Nº do documento')}/> }

                

                    <MaskedInput
                      mask="99/99/9999"
                      name="birth_date"
                      placeholder={translate('Data de Nascimento (DD/MM/YYYY)')}
                    />


<SelectSimple setCallback={ (value) => {
  setStateValue(value);
}} options={states} name="state" label="Estado"/>

<SelectSimple options={cities} name="city" label="Município"/> 

     
<aside style={{display:'flex', justifyContent:'flex-start', width: '100%'}}>
<CheckboxInput
              style={{ display: 'flex'  }}
              name="instituicao"
              unique={true}
              preText={(
                <>
                  {' '}
                  Você é
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Professor`,
                  label: 'Professor',
                  value: `Professor`,
                },
                {
                  id: `Gestor Escolar`,
                  label: 'Gestor Escolar',
                  value: `Gestor Escolar`,
                },
                {
                  id: `Público Geral`,
                  label: 'Público Geral',
                  value: `Público Geral`,
                },
 
              ]}
            />
</aside>
<aside style={{display:'flex', justifyContent:'flex-start', width: '100%'}}>
<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  O {authTitle} deseja enviar comunicações, via e-mail e SMS, tais como conteúdos especiais, newsletter, promoções e divulgações do projeto. Assinale abaixo se você aceita recebê-las:.
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, aceito receber comunicações por e-mail e SMS`,
                  label: 'Sim, aceito receber comunicações por e-mail e SMS',
                  value: `Sim, aceito receber comunicações por e-mail e SMS`,
                },
                {
                  id: `Aceito apenas receber comunicações por e-mail`,
                  label: 'Aceito apenas receber comunicações por e-mail',
                  value: `Aceito apenas receber comunicações por e-mail`,
                },
                {
                  id: `Aceito apenas receber comunicações por SMS`,
                  label: 'Aceito apenas receber comunicações por SMS',
                  value: `Aceito apenas receber comunicações por SMS`,
                },
                {
                  id: `Não aceito receber comunicações`,
                  label: 'Não aceito receber comunicações',
                  value: `Não aceito receber comunicações`,
                },
              ]}
            />
</aside>
<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#fff',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li e aceito a')}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#fff' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('POLÍTICA DE PRIVACIDADE')}
{' '}
                  </Link>
{' '}
                </strong>
                {translate('do evento.')}
              </p>
              <aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
</aside>

<aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
  {errorMessage && <Error>{errorMessage.map( text => <div>{text}</div> )}</Error>}
</aside>
                    <Button type="submit">
                      {translate('Quero me inscrever!')}

                    </Button>
                  </Form>
           
          </aside>
    </>
  );
};

export default FormContainer;
