import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Convidados: React.FC = () => {


  const gallery = [
    { title: 'andy', url : 'apoio/palestrantes/1andy.jpg'},
    { title : 'saras', url : 'apoio/palestrantes/2saras.jpg'},
    { title : 'daniela', url : 'apoio/palestrantes/3daniela.jpg'},
    { title : 'fabiana', url : 'apoio/palestrantes/4fabiana.jpg'},
    { title : 'fernando', url : 'apoio/palestrantes/5fernando.jpg'},
    { title : 'carol', url : 'apoio/palestrantes/6carol.jpg'},
    { title : 'lilian', url : 'apoio/palestrantes/7lilian.jpg'},
    { title : 'manu', url : 'apoio/palestrantes/8manu.jpg'},
    { title : 'fabio', url : 'apoio/palestrantes/9fabio.jpg'},
    { title : 'tiago', url : 'apoio/palestrantes/10tiago.jpg'},
    { title : 'fernando-d', url : 'apoio/palestrantes/11fernando-d.jpg'},
    { title : 'claudia', url : 'apoio/palestrantes/12claudia.jpg'},
    { title : 'maria', url : 'apoio/palestrantes/13maria.jpg'},
    { title : 'gustavo', url : 'apoio/palestrantes/14gustavo.jpg'},
    { title : 'priscila', url : 'apoio/palestrantes/15priscila.jpg'},
    { title : 'geraldo', url : 'apoio/palestrantes/16geraldo.jpg'},
    { title : 'guilhermina', url : 'apoio/palestrantes/17guilhermina.jpg'},
    
  ]



  return (
    <>


      <Container >
        <h2>
         CONVIDADOS
        </h2>

        <ImageGallery data={gallery}/>
      </Container>
    
    
    </>
  );
};
export default Convidados;
